import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { css } from '@emotion/react'

import { FaLightbulb, FaInfoCircle } from "react-icons/fa"

const PauseTemplate = (props) => {
    const project = props.data.pause.data
    const title = project.Name
    const intro = project.Intro
    const desc = project.Description.childMarkdownRemark.html
    const tips = project.Tips
    const category = props.data.pause.fields.category
    const image = project.Images?.localFiles[0]?.childImageSharp.gatsbyImageData

    // console.log(desc)

    return (
        <Layout>
            <Seo title={title} />

            <div css={main}>
                {image && <div css={media}>
                    <GatsbyImage image={image} alt={title}/>
                </div>}

                <div css={meta}>
                    <div>{category}</div>
                    <h1>⏲ {title}</h1>

                    {intro && 
                        <div css={[infoStyle, darkStyle]}><FaLightbulb/> {intro}. 
                            Now, find a quiet space where you won't be disturbed, and allow yourself a break of 5 minutes. Start by taking a few deep breaths, then do the following exercise.
                        </div>}
                    Let's take a pause now...

                    {desc && <div dangerouslySetInnerHTML={{__html: desc}} css={richText}/>}

                    {tips && <div css={infoStyle}><FaInfoCircle /> {tips}</div>}
                </div>
            </div>
        </Layout>
    )
}

export default PauseTemplate

const main = css`
    display: flex;
    align-items: center;
    padding: 50px 0;
    gap: 30px;

    @media screen and (max-width: 900px) {
        flex-wrap: wrap;
    }
`

const media = css`
    width: 30%;
    min-width: 280px;
    flex-shrink: 0;

    @media screen and (max-width: 900px) {
        width: 100%;
        min-width: auto;
    }

    .gatsby-image-wrapper {
        border-radius: 5px
    }
`

const meta = css`
    // margin-left: 30px;

    h1 {
        font-size: 40px;

        @media screen and (max-width: 900px) {
            font-size: 25px;
        }
    }

    > div:first-child {
        text-transform: capitalize;
        display: inline-block;
        padding: 3px 5px;
        border-radius: 2px;
        background-color: #333;
        color: white;
        font-size: 15px;
        line-height: 1;
        margin-bottom: 10px;
    }
`

const infoStyle = css`
    padding: 13px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    line-height: 1.3;
    margin-bottom: 1rem;
    display: inline-block;

    svg {
        margin-bottom: -2px;
        opacity: .33;
        margin-right: 5px;
    }
`

const darkStyle = css`
    background-color: #333;
    color: white;

    svg {
        fill: white;
    }
`

const richText = css`
    h1, h2 {
        font-size: 1.2rem;
        margin-top: 1rem;
        margin-bottom: .4rem;
    }

    p {
        margin-bottom: .8rem;
    }

    ol, ul {
        margin-bottom: .8rem;
    }

    li {
        margin-bottom: .3rem;
    }
`

export const query = graphql`
  query($id: String!) {
    pause: airtable(id: {eq: $id}) {
        id
        table
        data {
            Name
            Intro
            Description {
                childMarkdownRemark {
                    html
                }
            }
            Tips
            Images {
                localFiles {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                      )
                    }
                }
            }
        }
        fields {
            category
        }
      }
  }
`